.building-table {
  tbody {
    tr:hover {
      z-index: 9999;
      border: solid 4px #1888ff;
      cursor: pointer;
      td {
        background-color: #bbdefb !important;
      }
    }
  }
  flex-shrink: 0;
}

.unit-table {
  tbody {
    tr:hover {
      z-index: 9999;
      border: solid 4px #1888ff;
      cursor: pointer;
      td {
        background-color: #bbdefb !important;
      }
    }
  }
  flex-shrink: 0;
}

.info-textbox {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 75%;
  margin-left: 50px;
  margin-top: 50px;
}

.info-textbox h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: center;
  border-bottom: 3px solid #ccc;
  padding-bottom: 5px;
}

.info-textbox p {
  font-size: 1rem;
  line-height: 1.6;
}

.info-textbox ul {
  padding: 0 20px;
}

.info-textbox li {
  padding: 10px 0;
  list-style-type: "🔥";
}
